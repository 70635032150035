import cx from "classnames";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

type Props = {
  children: ReactElement;
  slideNumber: number;
};

export function OnboardingSlide({
  children,
  slideNumber,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.onboardingSlideContainer)}>
      <div className={cx(styles.imageContainer)}>{children}</div>
      <div className={cx(styles.onboardingInfo)}>
        <p className={cx(styles.onboardingTitle)}>
          {t(`onboarding:tutorials.${slideNumber}.title`)}
        </p>
        <p className={cx(styles.onboardingDescription)}>
          {t(`onboarding:tutorials.${slideNumber}.body`)}
        </p>
      </div>
    </div>
  );
}
