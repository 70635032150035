import { PublicationShortname } from "@src/app/auth/serviceIdentifier";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { BottomContainer } from "../BottomContainer";
import { CalendarContainer } from "../CalendarContainer";
import { TopContainer } from "../TopContainer";
import styles from "./index.module.scss";

type DrawerContainerProps = {
  theme?: PublicationShortname | null;
  showDrawer: boolean;
  isMobileView: boolean;
  toggleDrawer: () => void;
};
type DrawerPageContextType = {
  setHideDrawer: (_: boolean) => void;
  hideDrawer: boolean;
  swipeRight?: boolean;
  setSwipeRight: (_: boolean | undefined) => void;
  enterLeft?: boolean;
  setEnterLeft: (_: boolean | undefined) => void;
  theme?: PublicationShortname | null;
};
export const DrawerPageContext = React.createContext<DrawerPageContextType>({
  setHideDrawer: () => {},
  hideDrawer: false,
  swipeRight: undefined,
  setSwipeRight: () => {},
  enterLeft: undefined,
  setEnterLeft: () => {},
  theme: null,
});

export const DrawerContainer = ({
  showDrawer,
  theme,
  isMobileView,
  toggleDrawer,
}: DrawerContainerProps) => {
  const [hideDrawer, setHideDrawer] = useState<boolean>(false);
  const [swipeRight, setSwipeRight] = useState<boolean | undefined>(undefined);
  const [enterLeft, setEnterLeft] = useState<boolean | undefined>(undefined);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        toggleDrawer(); // Close the modal if the click is outside
      }
    };

    if (showDrawer) {
      // Add event listener when modal is open
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Cleanup event listener when modal is closed
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Cleanup the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDrawer, toggleDrawer]);

  return (
    <DrawerPageContext.Provider
      value={{
        setHideDrawer,
        hideDrawer,
        swipeRight,
        setSwipeRight,
        enterLeft,
        setEnterLeft,
        theme,
      }}
    >
      {isMobileView ? (
        <div
          className={cx({
            [styles.drawerContainer]: true,
            [styles.show]: showDrawer,
            [styles.hide]: hideDrawer,
          })}
          data-testid="drawer"
        >
          <TopContainer />
          <div className={cx(styles.drawerCalenderContainer)}>
            <CalendarContainer />
            <BottomContainer />
          </div>
        </div>
      ) : (
        <div className={cx(styles.calendarOverlay)}>
          <div
            ref={modalRef}
            className={cx({
              [styles.calenderTooltip]: true,
              [styles.show]: showDrawer,
              [styles.hide]: hideDrawer,
            })}
          >
            <CalendarContainer />
          </div>
        </div>
      )}
    </DrawerPageContext.Provider>
  );
};
