import { ResponseType } from "@sphtech/web2-core/ssr";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import ErrorIcon from "../../assets/icons/error-icon.svg";
import PageNotFoundIcon from "../../assets/page-not-found-icon.svg";
import SPHLogo from "../../assets/SPH24-Logo.svg";
import styles from "./ErrorPage.module.scss";

const LIMIT_EXCEEDED_ERROR: string = "ACCESS_LIMIT_EXCEEDED";

type ErrorContent = {
  errorImage: string;
  title: string;
  description: string | React.ReactElement;
};

const clientErrorContent: ErrorContent = {
  errorImage: ErrorIcon,
  title: "An error has occurred",
  description:
    "It looks like there was a problem with the request. Please check if you have entered the right link, or try to reload the page in a few minutes. ",
};

const limitExceedErrorContent: ErrorContent = {
  errorImage: ErrorIcon,
  title: "Sorry, you have reached the maximum access limit for this link. ",
  description: (
    <>
      <p>
        For assistance, please contact our customer service hotline at{" "}
        <a className={cx(styles.errorRedirectLink)} href="tel:+6563883838">
          +65 6388 3838
        </a>{" "}
        or email us at{" "}
        <a className={cx(styles.errorRedirectLink)} href="circs@sph.com.sg">
          circs@sph.com.sg
        </a>
        .
      </p>

      <p>
        Our operating hours are from 8:30 am to 6:00 pm, Mondays to Fridays. We
        are closed on public holidays.
      </p>
    </>
  ),
};

const pageNotFoundContent: ErrorContent = {
  errorImage: PageNotFoundIcon,
  title: "Oops! Page not found",
  description:
    "The page you were looking for could not be found. It may have been removed, or is temporarily unavailable. Check the URL for any errors, or try going back to the previous page.",
};

const serverErrorContent: ErrorContent = {
  errorImage: ErrorIcon,
  title: "Something went wrong",
  description: (
    <>
      <p>
        It looks like there was an error with the server, and we are currently
        working to fix this. In the meantime, try to refresh the page or come
        again in a few minutes.
      </p>
      <br />
      <p>
        Otherwise, get help from our customer service by reaching out to us
        at&nbsp;
        <a className={cx(styles.errorRedirectLink)} href="circs@sph.com.sg">
          circs@sph.com.sg
        </a>
      </p>
    </>
  ),
};

type Respose = {
  type?: ResponseType;
  statusCode?: number;
};

type Props = {
  response?: Respose;
};

function ErrorPage({ response }: Props): React.ReactElement {
  const [content, setContent] = useState<ErrorContent>(clientErrorContent);
  const [searchParams, setSearchParams] = useSearchParams();

  const setErrorContent = () => {
    if (searchParams.get("error_message") === LIMIT_EXCEEDED_ERROR) {
      setContent(limitExceedErrorContent);
    } else if (response?.statusCode === 404) {
      setContent(pageNotFoundContent);
    } else if (response?.type === ResponseType.SERVER_ERROR) {
      setContent(serverErrorContent);
    } else {
      setContent(clientErrorContent);
    }
  };

  useEffect(() => {
    setErrorContent();
  }, []);

  return (
    <>
      <div className={styles.errorPage}>
        <div className={styles.errorHeader}>
          <img src={SPHLogo} alt="SPH Logo" />
        </div>
        <div className={cx(styles.errorPageWrapper)}>
          <img
            src={content.errorImage}
            alt="error icon"
            className={cx(styles.erroricon)}
          />
          <div className={cx(styles.errorTextWrapper)}>
            {response?.statusCode && (
              <div className={cx(styles.errorTextCode)}>
                Error {response.statusCode}
              </div>
            )}
            <div className={cx(styles.errorTextTitle)}>{content.title}</div>
            <div className={cx(styles.errorTextDescription)}>
              {content.description}
            </div>
          </div>
        </div>
        <div className={cx(styles.errorFooter)}>
          <div>Copyright © 2024 SPH Media Limited. All Rights Reserved.</div>
        </div>
      </div>
    </>
  );
}

export default ErrorPage;
