import "./styles/index.css";
import "./i18n";

import { NeuronProvider, SPAConfig } from "@sphtech/neuron";
import { RenderContext } from "@sphtech/web2-core/ssr";
import { ReactElement, StrictMode, useContext } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Outlet, ScrollRestoration } from "react-router-dom";

import AppContextHOC from "./components/AppContextHOC";
import { init as SentryInit, captureConsoleIntegration } from "@sentry/react";

SentryInit({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  maxBreadcrumbs: 50,
  integrations: [
    captureConsoleIntegration({
      levels: ["error"],
    }),
  ],
  environment: import.meta.env.VITE_ENVIRONMENT,
  enabled: import.meta.env.MODE !== "development",
});

const neuronConfig: SPAConfig = {
  sessionApiUrl: import.meta.env.VITE_NEURON_SESSION_API_URL,
  sessionApiToken: import.meta.env.VITE_NEURON_SESSION_API_TOKEN,
  trackedSPAEvents: [
    {
      eventType: "click",
      selector: "a, button",
    },
  ],
};

export default function App(): ReactElement {
  const renderContext = useContext(RenderContext);

  return (
    <StrictMode>
      <ScrollRestoration />
      <HelmetProvider context={renderContext.helmet}>
        <AppContextHOC>
          <NeuronProvider config={neuronConfig}>
            <Outlet />
          </NeuronProvider>
        </AppContextHOC>
      </HelmetProvider>
    </StrictMode>
  );
}
